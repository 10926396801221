import React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";

import Layout from "../components/layout";
import SEO from "../components/seo";

const BlogPostTemplate = props => {
  const post = props.data.mdx;
  const siteTitle = props.data.site.siteMetadata.title;
  const siteUrl = props.data.site.siteMetadata.siteUrl;
  const lang = post.frontmatter.lang?.toLowerCase();

  return (
    <Layout location={props.location} title={siteTitle} lang={lang}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        lang={post.frontmatter.lang}
        imageProp={post.frontmatter.image}
        keywords={["Dyanmics 365", "D365", "PowerApps", "PowerPlatform"]}
        urlProp={`${siteUrl}/${post.frontmatter.parenturl}/${post.slug}`}
      />
      <div className="relative py-16 bg-white overflow-hidden rounded-md md:w-full lg:w-10/12 mx-auto md:px-8">
        <div className="border-b-2">
          <h1 className="p-4">
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              {post.frontmatter.title}
            </span>
          </h1>
        </div>

        <div className="mt-6 prose-indigo prose-lg text-gray-700 mx-auto px-8">
          <MDXRenderer>{post.body}</MDXRenderer>
        </div>
        <hr />
      </div>
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!, $lang: String!) {
    site {
      siteMetadata {
        title
        author
        siteUrl
      }
    }
    mdx(fields: { slug: { eq: $slug } }, frontmatter: { lang: { eq: $lang } }) {
      id
      excerpt(pruneLength: 160)
      body
      slug
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        image
        parenturl
        lang
      }
    }
  }
`;
